import React from "react"
import { Button, Center, Flex } from "@chakra-ui/react"

import { Icon } from "@app/components/Icon"

type Props = {
  hasPreviousPage: boolean
  getPreviousPage: () => void
  hasNextPage: boolean
  getNextPage: () => void
}

const buttonStyles = {
  d: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  w: "10",
  h: "10",
  borderRadius: "full",
  bg: "background.beige",
  _hover: {
    bg: "brand.secondaryFocus",
    color: "background.white",
  },
  _disabled: {
    bg: "background.inputDisabled",
    cursor: "unset",
    _hover: {
      bg: "background.inputDisabled",
      color: "unset",
    },
  },
}

const CollectionPagination: React.FC<Props> = ({ hasPreviousPage, getPreviousPage, hasNextPage, getNextPage }) => {
  return (
    <Flex alignItems="center" justifyContent="center" gap="2" pt={{ base: "7", lg: "12" }} pb={{ base: "8", lg: "18" }}>
      <Button variant="unstyled" isDisabled={!hasPreviousPage} onClick={getPreviousPage} {...buttonStyles}>
        <Center as="span" w="4" h="4">
          <Icon name="arrows/chevron-left" width="100%" height="100%" />
        </Center>
      </Button>

      <Button variant="unstyled" isDisabled={!hasNextPage} onClick={getNextPage} {...buttonStyles}>
        <Center as="span" w="4" h="4">
          <Icon name="arrows/chevron-right" width="100%" height="100%" />
        </Center>
      </Button>
    </Flex>
  )
}

export default React.memo(CollectionPagination)
