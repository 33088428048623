import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { Container } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useCore } from "@app/hooks/useCore"
import { useShopifyCollection, useShopify } from "@app/hooks/useShopify"
import SimpleHero from "@app/components/Sections/SimpleHero/SimpleHero"
import CollectionGrid from "@app/components/Collection/CollectionGrid"
import CollectionPagination from "@app/components/Collection/CollectionPagination"
import CollectionFilters from "@app/components/Collection/CollectionFilters"
import ProductAccordion from "@app/components/Sections/ProductAccordion/ProductAccordion"
import Text from "../Sections/Text/Text"

import { Props } from "@app/templates/collection"

import { NormalisedProduct } from "@root/types/custom-types/Product/Product"
import { ProductNode } from "@root/types/custom-types/Product/ProductNode"

const Collection: React.FC<Props> = ({ serverData, data: { template, collection: rawCollection } }) => {
  const {
    shopify: { productEdges, pageInfo },
  } = serverData

  const { collectionNormaliser, productNormaliser } = useShopify()
  const { activeCollection, setActiveCollection } = useAppContext()
  const {
    helpers: { ErrorBoundary, isDomReady },
  } = useCore()

  const containerRef = useRef<HTMLDivElement>(null)

  const sections = useMemo(() => template?.sections as unknown as any[], [template?.sections])
  const collection = useMemo(() => collectionNormaliser(rawCollection), [rawCollection, collectionNormaliser])
  const filterItems = useMemo(() => collection?.collectionFilters, [collection.collectionFilters])

  const accordionBlocks = useMemo(() => rawCollection?.accordionBlocks, [rawCollection?.accordionBlocks])
  const seoTitle = useMemo(() => (collection?.seoContent?.length ? collection?.seoContent[0]?.title : null), [collection?.seoContent])
  const seoContent = useMemo(() => (collection?.seoContent?.length ? collection?.seoContent[0]?.content : null), [collection?.seoContent])

  const {
    getPreviousPage,
    getNextPage,
    hasPreviousPage: shopifyHasPreviousPage,
    hasNextPage: shopifyHasNextPage,
    collection: shopifyCollection,
    isLoading: shopifyLoading,
    page,
    setPage,
  } = useShopifyCollection({
    handle: collection.handle,
    pageSize: 16,
    firstImages: 2,
    after: pageInfo.endCursor,
  })

  const collectionProducts: NormalisedProduct[] = useMemo(() => {
    if (shopifyCollection?.products?.length && page > 1) return shopifyCollection.products

    return productEdges?.map((edge: { node: ProductNode }) => productNormaliser(edge.node))
  }, [productEdges, productNormaliser, shopifyCollection?.products, page])

  const hasPreviousPage = useMemo(() => {
    if (page === 1) return pageInfo.hasPreviousPage
    return shopifyHasPreviousPage
  }, [page, pageInfo.hasPreviousPage, shopifyHasPreviousPage])

  const hasNextPage = useMemo(() => {
    if (page === 1) return pageInfo.hasNextPage
    return shopifyHasNextPage
  }, [page, pageInfo.hasNextPage, shopifyHasNextPage])

  const getPrevPageHandler = useCallback(() => {
    if (page === 2) {
      setPage(1)
    } else {
      getPreviousPage()
    }

    if (containerRef.current) setTimeout(() => containerRef.current?.scrollIntoView({ behavior: "smooth" }), 400)
  }, [page, setPage, getPreviousPage])

  const getNextPageHandler = useCallback(() => {
    if (page === 1) {
      setPage(2)
    } else {
      getNextPage()
    }

    if (containerRef.current) setTimeout(() => containerRef.current?.scrollIntoView({ behavior: "smooth" }), 400)
  }, [page, setPage, getNextPage])

  const isLoading = !!shopifyCollection?.products?.length && shopifyLoading

  useEffect(() => {
    if (collection?.id !== activeCollection?.id) setActiveCollection(collection)
  }, [collection, activeCollection?.id, setActiveCollection])

  const heroTitle = useMemo(
    () => (template?.bannerTitle ? template?.bannerTitle : collection?.title),
    [template?.bannerTitle, collection?.title]
  )

  return (
    <>
      <SimpleHero title={heroTitle} image={template?.bannerImages} />

      <Container ref={containerRef} maxW={"container.4xl"} px="0">
        <CollectionFilters items={filterItems} />

        <CollectionGrid items={collectionProducts} isLoading={isLoading} />

        <CollectionPagination
          hasPreviousPage={hasPreviousPage}
          getPreviousPage={getPrevPageHandler}
          hasNextPage={hasNextPage}
          getNextPage={getNextPageHandler}
        />
      </Container>

      {isDomReady && (
        <ErrorBoundary>
          {sections?.map((section: any) => {
            const name = section._type?.replace("section", "")
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            const SectionComponent = require(`../Sections/${name}/${name}`).default
            return <SectionComponent key={section._key?.toString()} name={name} {...section} />
          })}
        </ErrorBoundary>
      )}
      {accordionBlocks?.length ? <ProductAccordion accordionBlocks={accordionBlocks} /> : null}
      {seoContent?.length ? <Text content={seoContent} title={seoTitle} /> : null}
    </>
  )
}

export default Collection
